export const StatKeyDictionary = {
	"activitiesCleared": "Activities Cleared",
	"activitiesEntered": "Activities Entered",
	"assists": "Assists",
	"totalDeathDistance": "Total Death Distance",
	"averageDeathDistance": "Average Death Distance",
	"totalKillDistance": "Total Kill Distance",
	"averageKillDistance": "Average Kill Distance",
	"kills": "Kills",
	"secondsPlayed": "Seconds Played",
	"deaths": "Deaths",
	"averageLifespan": "Average Lifespan",
	"bestSingleGameKills": "Best Single Game Kills",
	"bestSingleGameScore": "Best Single Game Score",
	"opponentsDefeated": "Opponents Defeated",
	"efficiency": "Efficiency",
	"killsDeathsRatio": "K/D (without assists)",
	"killsDeathsAssists": "K/D (with assists)",
	"objectivesCompleted": "Objectives Completed",
	"precisionKills": "Precision Kills",
	"resurrectionsPerformed": "Resurrections Performed",
	"resurrectionsReceived": "Resurrections Received",
	"score": "Score",
	"heroicPublicEventsCompleted": "Heroic Public Events Completed",
	"adventuresCompleted": "Adventures Completed",
	"suicides": "Suicides",
	"weaponKillsAutoRifle": "Weapon Kills (Auto Rifle)",
	"weaponKillsBeamRifle": "Weapon Kills (Beam Rifle)",
	"weaponKillsBow": "Weapon Kills (Bow)",
	"weaponKillsGlaive": "Weapon Kills (Glaive)",
	"weaponKillsFusionRifle": "Weapon Kills (Fusion Rifle)",
	"weaponKillsHandCannon": "Weapon Kills (Hand Cannon)",
	"weaponKillsTraceRifle": "Weapon Kills (Trace Rifle)",
	"weaponKillsMachineGun": "Weapon Kills (Machine Gun)",
	"weaponKillsPulseRifle": "Weapon Kills (Pulse Rifle)",
	"weaponKillsRocketLauncher": "Weapon Kills (Rocket Launcher)",
	"weaponKillsScoutRifle": "Weapon Kills (Scout Rifle)",
	"weaponKillsShotgun": "Weapon Kills (Shotgun)",
	"weaponKillsSniper": "Weapon Kills (Sniper)",
	"weaponKillsSubmachinegun": "Weapon Kills (SMG)",
	"weaponKillsRelic": "Weapon Kills (Relic)",
	"weaponKillsSideArm": "Weapon Kills (Sidearm)",
	"weaponKillsSword": "Weapon Kills (Sword)",
	"weaponKillsAbility": "Weapon Kills (Ability)",
	"weaponKillsGrenade": "Weapon Kills (Grenade)",
	"weaponKillsGrenadeLauncher": "Weapon Kills (Grenade Launcher)",
	"weaponKillsSuper": "Weapon Kills (Super)",
	"weaponKillsMelee": "Weapon Kills (Melee)",
	"weaponBestType": "Weapon Best Type",
	"allParticipantsCount": "All Participants Count",
	"allParticipantsScore": "All Participants Score",
	"allParticipantsTimePlayed": "All Participants Time Played",
	"longestKillSpree": "Longest Kill Spree",
	"longestSingleLife": "Longest Single Life",
	"mostPrecisionKills": "Most Precision Kills",
	"orbsDropped": "Orbs Dropped",
	"orbsGathered": "Orbs Gathered",
	"publicEventsCompleted": "Public Events Completed",
	"remainingTimeAfterQuitSeconds": "Remaining Time After Quit Seconds",
	"teamScore": "Team Score",
	"totalActivityDurationSeconds": "Total Activity Duration Seconds",
	"fastestCompletionMs": "Fastest Completion (ms)",
	"longestKillDistance": "Longest Kill Distance",
	"highestCharacterLevel": "Highest Character Level",
	"highestLightLevel": "Highest Light Level",
	"fireTeamActivities": "Fireteam Activities",
	// PvP only:
	"activitiesWon": "Activities Won",
	"averageScorePerKill": "Average Score Per Kill",
	"averageScorePerLife": "Average Score Per Life",
	"winLossRatio": "Win-Loss-Ratio",
	"combatRating": "Combat Rating",
};
